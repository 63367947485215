import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { AuthenticationService } from 'app/auth/service';
import { SharedDataService } from './shared-data.service';
import { Contacto, Propiedad, Usuario } from 'app/models';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, interval, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cachedContactosList: Contacto[] = [];
  private cachedPropiedadesList: Propiedad[] = [];
  private cachedUsuariosList: Usuario[] = [];
  private logged = false;

  private destroy$ = new Subject<void>(); // Para cancelar la suscripción


  constructor(
    private apiSvc: ApiService,
    private authSvc: AuthenticationService,
    private sharedDataSvc: SharedDataService
  ) {
    this.apiSvc.init().then(() => {
      this.init();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  init() {
    console.log('CACHE SERVICE: Init');
    this.authSvc.currentUser.subscribe(user => {
      if (user) {
        this.logged = true;
        console.log('CACHE SERVICE: User logged in, refreshing data');

        //cargamos los datos de la API
        //para inicializar el cache
        this.fetchContactoListFromAPI({q_activos: 1}).subscribe();
        this.fetchPropiedadesListFromAPI({}).subscribe();
        this.fetchUsuariosListFromAPI({}).subscribe();

        //Nos suscribimos a un intervalo que refresca los datos del cache
        interval(environment.cache_auto_refresh)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            const maxContactoId = this.cachedContactosList.reduce((max, objeto) => (objeto.id > max ? objeto.id : max), 0);
            const maxPropiedadId = this.cachedPropiedadesList.reduce((max, objeto) => (objeto.id > max ? objeto.id : max), 0);
            const maxUsuarioId = this.cachedUsuariosList.reduce((max, objeto) => (objeto.id > max ? objeto.id : max), 0);

            forkJoin({
              res_contactos: this.apiSvc.getContactos({q_ids_desde: maxContactoId, q_activos: 1}),
              res_propiedades: this.apiSvc.getPropiedades({q_ids_desde: maxPropiedadId}),
              res_usuarios: this.apiSvc.getUsuarios({q_ids_desde: maxUsuarioId})
            })
            .subscribe({
              next: ({ res_contactos, res_propiedades, res_usuarios }) => {
                this.cachedContactosList.push(...res_contactos.contactos);
                this.cachedPropiedadesList = [...this.cachedPropiedadesList, ...res_propiedades.propiedades];
                this.cachedUsuariosList = [...this.cachedUsuariosList, ...res_usuarios.usuarios];
              },
              error: err => {
                console.log(err);
              }
            });
          });
      }
      else {
        this.logged = false;
        console.log('CACHE SERVICE: User logged out, clear data');
        this.cachedContactosList = [];
        this.cachedPropiedadesList = [];
        this.cachedUsuariosList = [];
      }
    });

    //Nos suscribimos a los eventos de creacion y modificacion de propiedades y contactos
    //para actualizar el cache
    this.sharedDataSvc.newPropiedadStream
      .pipe(takeUntil(this.destroy$))
      .subscribe((newPropiedad: Propiedad) => {
        this.cachedPropiedadesList.push(newPropiedad);
        this.cachedPropiedadesList.sort((a, b) => a.id - b.id);
      });
    this.sharedDataSvc.modifiedPropiedadStream
      .pipe(takeUntil(this.destroy$))
      .subscribe((modifiedPropiedad: Propiedad) => {
        //Reservado por si tenemos que modificar la propiedad en el cache
      });
    this.sharedDataSvc.newContactoStream
      .pipe(takeUntil(this.destroy$))
      .subscribe((newContacto: Contacto) => {
        this.cachedContactosList.push(newContacto);
        this.cachedContactosList.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));

      });
    this.sharedDataSvc.modifiedContactoStream
      .pipe(takeUntil(this.destroy$))
      .subscribe((modifiedContacto:Contacto) => {
        //Reservado por si tenemos que modificar el contacto en el cache
      });


  }



  private fetchContactoListFromAPI(filters): Observable<Contacto[]> {
    return this.apiSvc.getContactos(filters).pipe(
      map((response: any) => {
        this.cachedContactosList = response.contactos;
        return this.cachedContactosList;
      })
    );
  }

  private fetchPropiedadesListFromAPI(filters): Observable<Propiedad[]> {
    return this.apiSvc.getPropiedades(filters).pipe(
      map((response: any) => {
        this.cachedPropiedadesList = response.propiedades;
        return this.cachedPropiedadesList;
      })
    );
  }

  private fetchUsuariosListFromAPI(filters): Observable<Usuario[]> {
    return this.apiSvc.getUsuarios(filters).pipe(
      map((response: any) => {
        this.cachedUsuariosList = response.usuarios;
        return this.cachedUsuariosList;
      })
    );
  }




  getContactosList(): Observable<Contacto[]> {
    if (this.cachedContactosList.length > 0) {
      return of(this.cachedContactosList);
    }
    else {
      if (!this.logged) {
        return of([]);
      }
      return this.fetchContactoListFromAPI({q_activos: 1});
    }
  }

  getPropiedadesList(): Observable<Propiedad[]> {
    if (this.cachedPropiedadesList.length > 0) {
      return of(this.cachedPropiedadesList);
    }
    else {
      if (!this.logged) {
        return of([]);
      }
      return this.fetchPropiedadesListFromAPI({});
    }
  }

  getUsuariosList(): Observable<Usuario[]> {
    if (this.cachedUsuariosList.length > 0) {
      return of(this.cachedUsuariosList);
    }
    else {
      if (!this.logged) {
        return of([]);
      }
      return this.fetchUsuariosListFromAPI({});
    }
  }

}