import { Component, OnInit, Input, Output, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, FormArray, Validators } from '@angular/forms';
import { Relacion, Contacto, Propiedad, Oportunidad } from 'app/models';
import { ToastrService } from 'ngx-toastr';
import { CacheService, ApiService } from 'app/services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, forkJoin } from 'rxjs';
import { GlobalConstants } from 'app/app-config';

import { ContactoAddValidator } from 'app/validators/contactoAdd.validator';
import { Router } from '@angular/router';


@Component({
  selector: 'interes-add',
  templateUrl: './interes-add.component.html',
  styles: []
})
export class InteresAddComponent implements OnInit {

  preferredCountries = GlobalConstants.preferredCountries;

  @Input() public hook: string;
  @Input() public propiedad: Propiedad = null;
  @Input() public contacto: Contacto = null;
  @Input() public oportunidad: Oportunidad = null;
  @Output() nuevaRelacionEvent = new EventEmitter<Relacion>();

  private interesAddModalRef: NgbModalRef;
  @ViewChild('interesAddModal', { static: true }) public interesAddModal: ElementRef;
  public interesAddForm: FormGroup;
  public submitted = false;
  public contactos: Contacto[] = [];
  public propiedades: Propiedad[] = [];
  public title: string = 'Añadir intereses';
  public save_and_redirect: boolean = false;

  constructor(
    private apiSvc: ApiService,
    private cacheSvc: CacheService,
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    public modalSvc: NgbModal,
    private router: Router,
  ) {
  }

  get f() {
    return this.interesAddForm.controls;
  }

  ngOnInit(): void {

    if (this.hook == 'header') {
      this.title = 'Añadir intereses';
    }
    if (this.hook == 'propiedad') {
      this.title = 'Añadir interesados a la propiedad';
    }
    if (this.hook == 'contacto') {
      this.title = 'Añadir intereses al contacto';
    }
    if (this.hook == 'oportunidad') {
      this.title = 'Añadir intereses al contacto y oportunidad';
    }

    this.interesAddForm = this.formBuilder.group({
      propiedades_ids: ['', Validators.required],
      tipo_relacion_id: [3], //ojo que se resetea en openmodal
      //notas: [''],
      origen: ['', Validators.required],
      contacto_id: ['', Validators.required],
      contacto: this.formBuilder.group({
        nombre: [{ value: '', disabled: true }, Validators.required],
        telefonos_contacto: this.formBuilder.array([
          this.formBuilder.group({
            prefijo: [+34, Validators.required],
            telefono: [''],
            etiqueta: ['']
          })
        ]),
        emails_contacto: this.formBuilder.array([
          this.formBuilder.group({
            email: ['', Validators.email],
            etiqueta: ['']
          })
        ]),
      })
    });
    this.interesAddForm.get('contacto').disable();



  }


  openModal(param) {
    //Cargamos los datos aqui para tener la ultima version del cache siempre (GCH-460)
    //De lo contrario tendriamos que setear un observable en el constructor y actualizarlo cada vez que se añade una propiedad o contacto
    forkJoin({
      res_contactos: this.cacheSvc.getContactosList(),
      res_propiedades: this.cacheSvc.getPropiedadesList()
    }).subscribe({
      next: ({ res_contactos, res_propiedades }) => {
        this.contactos = [...res_contactos];
        const estados_permitidos = ['disponible', 'captacion', 'reservada'];
        this.propiedades = res_propiedades.filter(propiedad => estados_permitidos.includes(propiedad.estado));
      },
      error: err => {
        console.log(err);
      }
    });
    this.submitted = false;
    if (param == 'interesAdd') {
      this.interesAddForm.reset();
      this.interesAddForm.get('tipo_relacion_id').setValue(3);
      if (this.hook == 'propiedad') {
        this.interesAddForm.get('propiedades_ids').setValue([this.propiedad.id]);        
      }
      if (this.hook == 'contacto' || this.hook == 'oportunidad') {
        this.interesAddForm.get('contacto_id').setValue(this.contacto.id);
      }
      this.onChangePrefix({ dialCode: 34 });
      this.interesAddModalRef = this.modalSvc.open(this.interesAddModal);
    }
  }

  onRemove(event) {
    console.log(event);
  }

  contactoNavChange(event) { //Si cambiamos de pestaña en el modal tienen que cambiar las validaciones
    const contactoIdControl = this.interesAddForm.get('contacto_id');
    const contactoGroup = this.interesAddForm.get('contacto');
    if (event.nextId === 1) { //existente
      contactoGroup.disable();
      contactoIdControl.enable();
      contactoGroup.clearValidators();
      contactoGroup.updateValueAndValidity();
    }
    if (event.nextId === 2) { //nuevo
      contactoGroup.enable();
      contactoGroup.setValidators([ContactoAddValidator.alMenosUnContacto]);
      contactoGroup.updateValueAndValidity();
      contactoIdControl.disable();
    }
  }

  onChangePrefix(event) {
    const contacto = this.interesAddForm.get('contacto') as FormGroup;
    (contacto.get('telefonos_contacto') as FormArray).at(+0).get('prefijo').setValue(event.dialCode);
  }

  redirect(booleano) {
    this.save_and_redirect = booleano;
  }

  onSubmitInteres() {
    console.log('Submit nueva relacion');
    console.log('Interes form value', this.interesAddForm.value);

    this.submitted = true;

    if (this.interesAddForm.invalid) {
      console.log('Validate error', this.interesAddForm);
      this.toastSvc.error(
        'Error',
        'Datos no válidos'
      );
      return;
    }

    let data = JSON.parse(JSON.stringify(this.interesAddForm.value));
    const propiedades_ids = data.propiedades_ids;
    delete data.propiedades_ids;

    //hay dos formas de guardar los datos, si el contacto es nuevo o si es existente
    //* si es nuevo, primero creamos el contacto con los intereses como relacionados
    //* si es existente, mandamos el bloque de intereses con el contacto_id

    let contacto_nuevo = false;
    let contacto = null;



    if (data.hasOwnProperty('contacto')) {
      contacto = data.contacto;
      delete data.contacto;
      contacto_nuevo = true;
    }
    let intereses = [];
    propiedades_ids.forEach(propiedad_id => {
      let interes = {...data};
      if (this.oportunidad) {
        interes.oportunidad_id = this.oportunidad.id;
      }
      interes.propiedad_id = propiedad_id;
      intereses.push(interes);
    });


    if (contacto_nuevo) {
      contacto.relaciones = intereses;
      //una vez validado comprobamos que el telefono o el email no vayan vacios para no meter mierda en la bbdd
      if (contacto.emails_contacto[0].email == '' || contacto.emails_contacto[0].email == null) {
        delete contacto.emails_contacto;
      }
      if (contacto.telefonos_contacto[0].telefono == '' || contacto.telefonos_contacto[0].telefono == null) {
        delete contacto.telefonos_contacto;

      } else {

        // Formatear el número de teléfono en grupos de 3 dígitos
        let telefono = contacto.telefonos_contacto[0].telefono;
        telefono = telefono.replace(/\s/g, ''); // Eliminar espacios existentes
        telefono = telefono.split('').reverse().join(''); // Invertir la cadena
        telefono = telefono.match(/.{1,3}/g).join(' '); // Agrupar en grupos de 3
        telefono = telefono.split('').reverse().join(''); // Volver a invertir
        contacto.telefonos_contacto[0].telefono = telefono.trim();
      }
    }


    if (contacto_nuevo) {
      this.apiSvc.postContacto(contacto).subscribe({
        next: (response) => {
          console.log(response);
          this.interesAddModalRef.dismiss();

          //confeccionamos el array de relaciones para emitirlo
          if (this.hook == 'propiedad') {
            let relacion = response.contacto.relaciones.filter(relacion => relacion.tipo_relacion_id == 3 && relacion.propiedad_id == this.propiedad.id)[0];
            relacion.contacto = response.contacto;
            this.nuevaRelacionEvent.emit(relacion);
          }

          this.toastSvc.success(
            'Exito',
            'Intereses y contacto registrados'
          );
          if (this.save_and_redirect) {
            this.router.navigate(['/contactos/'+response.contacto.id]);
          }
        },
        error: (err) => {
          console.log(err);
          this.toastSvc.error(
            err.message,
            'Error',
          );
        }
      });
    }
    else {
      this.apiSvc.postRelacion(intereses).subscribe({
        next: (response) => {
          this.interesAddModalRef.dismiss();

          if (this.hook == 'propiedad' || this.hook == 'contacto' || this.hook == 'oportunidad') {
            response.relaciones.forEach(relacion => {
              this.nuevaRelacionEvent.emit(relacion);
            });
          }

          this.toastSvc.success(
            'Exito',
            'Intereses registrados'
          );

          if (this.save_and_redirect) {
            this.router.navigate(['/contactos/'+data.contacto_id]);
          }
        },
        error: (err) => {
          console.log(err);
          this.toastSvc.error(
            err.message,
            'Error',
          );
        }
      });
    }



  }

  customSearchContacto(term: string, item) {
    term = term.toLocaleLowerCase();

    const nombreMatches = item['nombre'].toLocaleLowerCase().indexOf(term) > -1;

    const telefonosMatches = item['telefonos_contacto'] && item['telefonos_contacto']
      .some(telefono => telefono['telefono'].toLocaleLowerCase().indexOf(term) > -1);

    const emailsMatches = item['emails_contacto'] && item['emails_contacto']
      .some(email => email['email'].toLocaleLowerCase().indexOf(term) > -1);

    return nombreMatches || telefonosMatches || emailsMatches;
  }





}
