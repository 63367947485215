import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { PropiedadesAlertModule } from 'app/components/propiedades-alert/propiedades-alert.module';
import { PropiedadesArrasAlertModule } from 'app/components/propiedades-arras-alert/propiedades-arras-alert.module';
import { TareasPendientesModule } from 'app/components/tareas-pendientes/tareas-pendientes.module';
import { CitasPendientesModule } from 'app/components/citas-pendientes/citas-pendientes.module';

import { PropiedadEstadoBadgeModule } from 'app/components/propiedad-estado-badge/propiedad-estado-badge.module';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';

const routes = [
  {
    path: 'sample',
    component: SampleComponent,
    data: { animation: 'sample' }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { animation: 'home' }
  }
];

@NgModule({
  declarations: [SampleComponent, HomeComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule, PropiedadesAlertModule, PropiedadesArrasAlertModule, TareasPendientesModule, CitasPendientesModule, PropiedadEstadoBadgeModule],
  exports: [SampleComponent, HomeComponent]
})
export class SampleModule {}
