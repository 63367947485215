import { Imagen } from "./imagen.model";
import { Propiedad } from "./propiedad.model";
import { Type } from 'class-transformer';
import { Grupo } from "./grupo.model";
import { EstadoPublicacionPortal } from "./estado-publicacion-portal.model";



export interface ISeoCmsPropiedadTranslation {
    locale: string,
    title: string,
    description: string,
    keywords: string
}

export interface ISeoCmsPropiedad {
    id: number,
    title: string,
    description: string,
    keywords: string,
    _translations: ISeoCmsPropiedadTranslation[]

}

export interface ICmsPropiedadTranslation {
    locale: string,
    slug: string,
    nombre: string,
    desc_corta: string,
    desc_larga: string,
    puntos_clave: string
}

export interface ICmsPropiedad {
    id: number,
    propiedad_id: number,
    imagen_destacada_id: number,
    created: Date,
    modified: Date,
    publicada: boolean,
    fecha_publicacion: Date,
    slug: string,
    nombre: string,
    frase_destacada: string,
    desc_corta: string,
    desc_larga: string,
    puntos_clave: string,
    ficha_propiedad: Propiedad
    cms_propiedades_relacionadas: CmsPropiedad[],
    grupos: Grupo[],
    seo_propiedad: ISeoCmsPropiedad,
    imagenes: Imagen[];
    imagen_destacada: Imagen,
    _translations: ICmsPropiedadTranslation[],
    estados_publicaciones_portales: EstadoPublicacionPortal[],
}

export class CmsPropiedad implements ICmsPropiedad {
    public id: number;
    public propiedad_id: number;
    public imagen_destacada_id: number;
    public created: Date;
    public modified: Date;
    public publicada: boolean;
    public fecha_publicacion: Date;
    public slug: string;
    public nombre: string;
    public frase_destacada: string;
    public desc_corta: string;
    public desc_larga: string;
    public puntos_clave: string;
    @Type(() => Propiedad)
    public ficha_propiedad: Propiedad;
    @Type(() => CmsPropiedad)
    public cms_propiedades_relacionadas: CmsPropiedad[];
    @Type(() => Grupo)
    public grupos: Grupo[];
    public seo_propiedad: ISeoCmsPropiedad;
    @Type(() => Imagen)
    public imagenes: Imagen[];
    @Type(() => Imagen)
    public imagen_destacada: Imagen;
    public _translations: ICmsPropiedadTranslation[];
    @Type(() => EstadoPublicacionPortal)
    public estados_publicaciones_portales: EstadoPublicacionPortal[];



    getProblemsPublicacionKyero(all = false) {

        let problems;

        if (this.ficha_propiedad) {
        
            problems = this.ficha_propiedad.getProblemsPublicacionKyero();                
        }

        problems.push({

            key: 'imagenes',
            nombre: 'Imágenes',
            msg: 'Es necesario subir alguna imagen',
            ayuda: 'Suba a alguna imagen a la ficha de la publicación',
            status: 0,
        });

        problems.push({

            key: 'imagenes_max',
            nombre: 'Imágenes (máximo 50)',
            msg: 'No se pueden subir más de 50 imágenes',
            ayuda: 'Elimine alguna imagen de la publicación',
            status: 0,
        });

        if (this.imagenes.length == 0) {

            problems = problems.map(p => p.key === 'imagenes' ? { ...p, status: 1 } : p);

        } else {

            if (this.imagenes.length > 50) {

                problems = problems.map(p => p.key === 'imagenes_max' ? { ...p, status: 1 } : p);
            }      
            
            this.imagenes.forEach(imagen => {

                let img = new Image(); 
     
                img.src = imagen.url_original();
                img.onload = function (event: any) {

                    if (event.currentTarget.width < 1280) {

                        problems.push({

                            key: 'imagen#' + imagen.id,
                            nombre: 'La imagen ' + imagen._translations['es'].descripcion + ' no llega al ancho mínimo de 1280px',
                            msg: 'La imagen ' + imagen._translations['es'].descripcion + ' no llega al ancho mínimo de 1280px',
                            ayuda: 'Suba una imagen con al menos 1280px de ancho',
                            status: 1,
                        });
                    }

                    if (event.currentTarget.width < 960) {

                        problems.push({

                            key: 'imagen#' + imagen.id,
                            nombre: 'La imagen ' + imagen._translations['es'].descripcion + ' no llega al alto mínimo de 960px',
                            msg: 'La imagen ' + imagen._translations['es'].descripcion + ' no llega al alto mínimo de 960px',
                            ayuda: 'Suba una imagen con al menos 960px de alto',
                            status: 1,
                        });
                    }
                }  

                /*
                img.onerror = function (event: any) {

                    problems.push({

                        key: 'imagen#' + imagen.id,
                        nombre: 'No se pudo carga la imagen ' + imagen._translations['es'].descripcion,
                        msg: 'No se pudo carga la imagen ' + imagen._translations['es'].descripcion,
                        ayuda: 'Revise la dirección URL de la imagen',
                        status: 1,
                    });
                }
                */
                
                
            });    
        }

        return problems;
    }

    getProblemsPublicacionIdealista(all = false) {

        let problems;

        if (this.ficha_propiedad) {
        
            problems = this.ficha_propiedad.getProblemsPublicacionIdealista();                
        }

        problems.push({

            key: 'imagenes',
            nombre: 'Imágenes',
            msg: 'Es necesario subir alguna imagen',
            ayuda: 'Suba a alguna imagen a la ficha de la publicación',
            status: 0,
        });

        if (this.imagenes.length == 0) {

            problems = problems.map(p => p.key === 'imagenes' ? { ...p, status: 1 } : p);

        } else {

            this.imagenes.forEach(imagen => {

                if (imagen.img_size > 33554432) {

                    problems.push({

                        key: 'imagen#' + imagen.id,
                        nombre: 'La imagen ' + imagen._translations['es'].descripcion + ' pesa más de 32Mb',
                        msg: 'La imagen ' + imagen._translations['es'].descripcion + ' pesa más de 32Mb',
                        ayuda: 'Reduzca el tamaño de la imagen',
                        status: 1,
                    });
                }
            });
        }

        return problems; 
    }

    getProblemsPublicacionThinkspain(all = false) {

        let problems;
 
        if (this.ficha_propiedad) {
        
            problems = this.ficha_propiedad.getProblemsPublicacionThinkspain();                
        }

        problems.push({

            key: 'imagenes', 
            nombre: 'Imágenes',
            msg: 'Es necesario subir alguna imagen',
            ayuda: 'Suba a alguna imagen a la ficha de la publicación',
            status: 0,
        });

        if (this.imagenes.length == 0) {

            problems = problems.map(p => p.key === 'imagenes' ? { ...p, status: 1 } : p);
        } 

        return problems;
    }

    getProblemsPublicacionFotocasa(all = false) {

        let problems;
 
        if (this.ficha_propiedad) {
        
            problems = this.ficha_propiedad.getProblemsPublicacionFotocasa();                
        }

        problems.push({

            key: 'imagenes', 
            nombre: 'Imágenes',
            msg: 'Es necesario subir alguna imagen',
            ayuda: 'Suba a alguna imagen a la ficha de la publicación',
            status: 0,
        });

        if (this.imagenes.length == 0) {

            problems = problems.map(p => p.key === 'imagenes' ? { ...p, status: 1 } : p);
        } 

        return problems;
    }

    getImageDimension(imgUrl){

        let img = new Image(); 
     
        img.src = imgUrl;
        img.onload = function (event: any) {

                console.log(event);

                return event.currentTarget;             
        } 
    }    
}






