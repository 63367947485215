import { Injectable, EventEmitter } from '@angular/core';
import { Relacion, Contacto, Propiedad } from '../models';

@Injectable({
  providedIn: 'root',
})


export class SharedDataService {

  modifiedRelacionStream = new EventEmitter<Relacion>();
  newRelacionStream      = new EventEmitter<Relacion>();

  modifiedPropiedadStream = new EventEmitter<Propiedad>();
  newPropiedadStream      = new EventEmitter<Propiedad>();

  modifiedContactoStream = new EventEmitter<Contacto>();
  newContactoStream      = new EventEmitter<Contacto>();


  private _emitNewElement(el: any) {
    console.log('emitting new element', el);
    switch (el.constructor.name) {
      case 'Relacion': this.newRelacionStream.emit(el); break;
      case 'Propiedad': this.newPropiedadStream.emit(el); break;
      case 'Contacto': this.newContactoStream.emit(el); break;
    }
  }

  private _emitModifiedElement(el: any) {
    console.log('emitting modified element', el);
    switch (el.constructor.name) {
      case 'Relacion': this.modifiedRelacionStream.emit(el); break;
      case 'Propiedad': this.modifiedPropiedadStream.emit(el); break;
      case 'Contacto': this.modifiedContactoStream.emit(el); break;
    }
  }

  newElement(data: any) {
    if (data.constructor === Array) {
      data.forEach(element => {
        this._emitNewElement(element);
      });
    }
    else {
      this._emitNewElement(data);
    }
  }

  modifiedElement(data: any) {
    if (data.constructor === Array) {
      data.forEach(element => {
        this._emitModifiedElement(element);
      });
    }
    else {
      this._emitModifiedElement(data);
    }
  }

}