import { Type } from 'class-transformer';
import { Usuario, Propiedad, Contacto } from 'app/models';




export interface IActividad {
    id: number,
    created: Date,
    autor_id: number,
    tipo: string,
    fecha: Date,
    fecha_fin: Date,
    titulo: string,
    descripcion: string,
    completada: boolean,
    completada_fecha: Date,
    prioridad: string,
    cancelada: boolean,
    cancelada_fecha: Date,
    cancelada_texto: string,
    autor: Usuario,
    usuarios: Usuario[],
    propiedades: Propiedad[],
    contactos: Contacto[],
}

export class Actividad implements IActividad {
    public id: number;
    public created: Date;
    public autor_id: number;
    public tipo: string;
    public fecha: Date;
    public fecha_fin: Date;
    public titulo: string;
    public descripcion: string;
    public completada: boolean;
    public completada_fecha: Date;
    public prioridad: string;
    public cancelada: boolean;
    public cancelada_fecha: Date;
    public cancelada_texto: string;
    @Type(() => Usuario)
    public autor: Usuario;
    @Type(() => Usuario)
    public usuarios: Usuario[];
    @Type(() => Propiedad)
    public propiedades: Propiedad[];
    @Type(() => Contacto)
    public contactos: Contacto[];

    static get modelName() {
        return 'Actividad';
    }

    estado() {
        if (this.completada) {
            return this.tipo == 'tarea' ? 'Completada':'Celebrada';
        }
        else if (this.fecha && new Date(this.fecha) < new Date()) {
            return this.tipo == 'tarea' ? 'Vencida':'Sin información';
        }
        else {
            return 'Pendiente';
        }
    }

    getTipoReunion() {
        if (this.tipo == 'reunion_captacion') {
            return 'Reunión captación';
        }
        if (this.tipo == 'reunion_venta') {
            return 'Reunión venta';
        }
        if (this.tipo == 'reunion_interna') {
            return 'Reunión interna';
        }
        if (this.tipo == 'cita_notarial') {
            return 'Cita notarial';
        }
        if (this.tipo == 'cita_reportaje') {
            return 'Cita reportaje';
        }
        if (this.tipo == 'otra') {
            return 'Otras reuniones';
        }
        return '';
    }

    getBadgeTipoReunion() {
        if (this.tipo == 'reunion_captacion') {
            return '<small class="badge badge-sm badge-info ">'+this.getTipoReunion()+'</small>';
        }
        if (this.tipo == 'reunion_venta') {
            return '<small class="badge badge-success ">'+this.getTipoReunion()+'</small>';
        }
        if (this.tipo == 'reunion_interna') {
            return '<small class="badge badge-danger ">'+this.getTipoReunion()+'</small>';
        }
        if (this.tipo == 'cita_notarial') {
            return '<small class="badge badge-secondary ">'+this.getTipoReunion()+'</small>';
        }
        if (this.tipo == 'cita_reportaje') {
            return '<small class="badge badge-warning ">'+this.getTipoReunion()+'</small>';
        }
        if (this.tipo == 'otra') {
            return '<small class="badge badge-primary ">'+this.getTipoReunion()+'</small>';
        }
        return '';
    }

    getBadgePrioridad() {
        if (this.prioridad == 'baja') {
            return '<small class="badge badge-sm badge-info ">'+this.prioridad+'</small>';
        }
        if (this.prioridad == 'media') {
            return '<small class="badge badge-warning ">'+this.prioridad+'</small>';
        }
        if (this.prioridad == 'alta') {
            return '<small class="badge badge-danger ">'+this.prioridad+'</small>';
        }
    }
}