import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AvatarModule } from 'ngx-avatar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { PropiedadesArrasAlertComponent } from './propiedades-arras-alert.component';
import { RouterModule } from '@angular/router';

import { PropiedadEstadoBadgeModule } from '../propiedad-estado-badge/propiedad-estado-badge.module';

@NgModule({
  declarations: [
    PropiedadesArrasAlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule, 
    MentionModule,
    PerfectScrollbarModule,
    AvatarModule,
    NgxDatatableModule,
    PropiedadEstadoBadgeModule
  ],
  exports: [
    PropiedadesArrasAlertComponent
  ]
})
export class PropiedadesArrasAlertModule {}
