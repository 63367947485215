import { environment } from 'environments/environment';




export interface IArchivo {
    id: number,
    nombre: string,
    descripcion: string,
    activo: boolean,
    orden: number,
    file: string,
    file_dir: string,
    file_size: number,
    file_type: string,
    error: string,
    modified: Date,
    created: Date,
    foreign_key: number,
    model: string,
    grp: string,
    adapter: string,

}

export class Archivo implements IArchivo {
    public id: number;
    public nombre: string;
    public descripcion: string;
    public activo: boolean;
    public orden: number;
    public file: string;
    public file_dir: string;
    public file_size: number;
    public file_type: string;
    public error: string;
    public modified: Date;
    public created: Date;
    public foreign_key: number;
    public model: string;
    public grp: string;
    public adapter: string;

    static get modelName() {
        return 'Archivo'
    }
}