import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/services';

import { Actividad } from 'app/models';

@Component({
  selector: 'tarea-list-item',
  template: `
    <li class="todo-item" [class.completed]="tarea.completada">
      <div class="todo-title-wrapper">
        <div class="todo-title-area">
          <i data-feather="more-vertical" class="drag-icon"></i>
          <div class="title-wrapper">

            <div class="custom-control custom-checkbox" *ngIf="es_dashboard == false">
              <input
                  type="checkbox"
                  class="custom-control-input todo-checkbox"
                  id="customCheck{{ tarea.id }}"
                  name="customCheck{{ tarea.id }}"
                  [checked]="tarea.completada"
                  (click)="$event.stopPropagation()"
                  (change)="checkboxStateChange($event)"
              />
              <label class="custom-control-label" for="customCheck{{ tarea.id }}"></label>
            </div>

            <div class="ml-1">

              <span *ngIf="tarea.prioridad" class="mr-25" [innerHtml]="tarea.getBadgePrioridad()"></span>
              <span class="todo-title ml-0">{{ tarea.titulo }}</span>

              <ng-container *ngFor="let propiedad of tarea.propiedades; let i = index; let isLast = last">
                <a [routerLink]="['/inventario/propiedades',propiedad.id]" class="ml-25 badge badge-primary">
                  {{propiedad.id}}
                </a>
              </ng-container>
              <br *ngIf="tarea.contactos.length > 0">
              <ng-container *ngFor="let contacto of tarea.contactos; let i = index; let isLast = last">
                <a class="mt-25" [routerLink]="['/contactos',contacto.id]">
                  {{contacto.nombre}}{{isLast ? '' : (i < tarea.contactos.length - 2) ? ', ' : ' y '}}
                </a>
              </ng-container>
            </div>



          </div>
        </div>
        <div class="todo-item-action">
          <small class="text-nowrap text-muted mr-50">{{ tarea.fecha | date: ' MMM d' }}</small>
          <small class="badge badge-sm badge-secondary" *ngIf="tarea.estado() == 'Vencida'">Vencida</small>
          <div class="avatar-group" *ngIf="tarea.usuarios.length > 0">
            <div placement="bottom" class="avatar pull-up"  *ngFor="let user of tarea.usuarios">
              <ngx-avatar size="30" src="{{user.imagen ? user.imagen.url_small():''}}" [name]="user.getFullName()"></ngx-avatar>
            </div>
          </div>
        </div>
      </div>
    </li>
  `,

})
export class TareaListItemComponent implements OnInit {
  // Input Decorator
  @Input() tarea: Actividad;
  @Input() es_dashboard: boolean = false;
  @Output() onUpdate = new EventEmitter<Actividad>();

  // Public
  public selected;

  /**
   * Constructor
   *
   * @param {TodoService} _todoService
   */
  constructor(
    private apiSvc: ApiService
  ) { }

  /**
   *
   * @param stateRef
   */
  checkboxStateChange(stateRef) {
    this.tarea.completada = stateRef.target.checked;

    let data = {
      id: this.tarea.id,
      completada: stateRef.target.checked
    }

    this.apiSvc.putActividad(this.tarea.id, data).subscribe({
      next: (response) => {
        this.onUpdate.emit(response.actividad);
      },
      error: (err) => {
        console.log(err);
      }
    });


  }

  ngOnInit(): void { }
}
