import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'app/services/loading.service';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private activeRequests = 0;

  constructor(private loadingService: LoadingService) {}


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    //No saltamos el loading si es un refresh del cache
    const hasQueryParam = request.params.has('q_ids_desde');

    // Mostrar el loading
    // console.log('Request count: ' + this.activeRequests);
    if (!hasQueryParam) {
      this.activeRequests++;
      this.loadingService.startLoading();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!hasQueryParam) {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            // console.log('All requests are done');
            // Ocultar el loading
            this.loadingService.stopLoading();
          }
        }
      })
    );
  }
}