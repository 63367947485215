import { Component, Input } from '@angular/core';
import { Propiedad } from '../../models/propiedad.model';

@Component({
  selector: 'propiedad-estado-badge',
  templateUrl: './propiedad-estado-badge.component.html',
  styleUrls: ['./propiedad-estado-badge.component.scss']
})
export class PropiedadEstadoBadgeComponent {

  @Input() propiedad: Propiedad;

  getEstadoClass(): string {

    return "badge-" + this.propiedad.estado;
  }
}