import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { Usuario } from 'app/models';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<Usuario>;
  public currentToken: Observable<String>;
  public currentTokenRefresh: Observable<String>;

  //private
  private currentUserSubject: BehaviorSubject<Usuario>;
	private currentTokenSubject = new BehaviorSubject(null);
	private currentTokenRefreshSubject = new BehaviorSubject(null);

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('usuario')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentTokenSubject = new BehaviorSubject<String>(JSON.parse(localStorage.getItem('token')));
    this.currentToken = this.currentTokenSubject.asObservable();
    this.currentTokenRefreshSubject = new BehaviorSubject<String>(JSON.parse(localStorage.getItem('token_refresh')));
    this.currentTokenRefresh = this.currentTokenRefreshSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }
  // getter: currentUserValue
  public get currentTokenValue(): String {
    return this.currentTokenSubject.value;
  }
  public get currentTokenRefreshValue(): String {
    return this.currentTokenRefreshSubject.value;
  }

  public setAccessToken(token: string): void {
    this.currentTokenSubject.next(token);
  }
  public setRefreshToken(token: string): void {
    this.currentTokenRefreshSubject.next(token);
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */

  requestResetPasword(email: string) {

    return this._http
      .post<any>(`${environment.AUTH_SERVER_ADDRESS}/request-reset-password`, {reference: email})
      .pipe(
        map(response => {
          console.log('AUTH SERVICE: Reset password success', response);
      })
    );
  }

  resetPasword(token: string) {

    return this._http
      .post<any>(`${environment.AUTH_SERVER_ADDRESS}/reset-password`, {token: token})
      .pipe(
        map(response => {
          response.usuario = plainToClass(Usuario, response.usuario);
          return response;
      })
    );
  }

  changePassword(usuario_id, data): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this._http.put<any>(`${environment.API_SERVER_ADDRESS}/usuarios/change-password/${usuario_id}`, data, httpOptions)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  login(email: string, password: string) {

    return this._http
      .post<any>(`${environment.AUTH_SERVER_ADDRESS}/login`, {username: email, password: password})
      .pipe(
				map(response => {
          console.log('AUTH SERVICE: Login success', response);
          //login successful if there's a jwt token in the response
          if (response.usuario && response.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('usuario', JSON.stringify(response.usuario));
            localStorage.setItem('token', JSON.stringify(response.token));
            localStorage.setItem('token_refresh', JSON.stringify(response.token_refresh));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'Te has identificado como ' +
                  response.usuario.username,
                '👋 Bienvenido, ' + response.usuario.first_name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            response.usuario = plainToClass(Usuario, response.usuario);

            // notify
            this.currentUserSubject.next(response.usuario);
            this.currentTokenSubject.next(response.token);
            this.currentTokenRefreshSubject.next(response.token_refresh);
          }

          return response.usuario;
        })
		  );
  }

  updateUsuario(usuario: Usuario) {
    //console.log('updateUsuario', usuario);
    localStorage.setItem('usuario', JSON.stringify(usuario));
    this.currentUserSubject.next(usuario);
  }

  /**
   * User logout
   *
   */
  logout() {
    console.log('AUTH SERVICE: logout');
    // remove user from local storage to log user out
    localStorage.removeItem('usuario');
    localStorage.removeItem('token');
    localStorage.removeItem('token_refresh');
    // notify
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.currentTokenRefreshSubject.next(null);
    this._router.navigate(['/pages/authentication/login']);
  }

  refreshTokens(): Observable<any> {
    const refreshToken = this.currentTokenRefreshSubject.value;
    return this._http.post<any>(`${environment.AUTH_SERVER_ADDRESS}/refresh-session`, { refresh_token: refreshToken })
  }

}
