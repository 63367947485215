import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedDataService } from './shared-data.service';
import { plainToClass } from 'class-transformer';
import {
  Oportunidad,
  Contacto,
  Imagen,
  EnlacePrensa,
  Parcela,
  Relacion,
  TelefonoContacto,
  Usuario,
  Propiedad,
  Precio,
  Mensaje,
  Actividad,
  ReferenciaCatastral,
  Log,
  Banner,
  CmsPropiedad,
  Grupo,
  Portal,
  EstadoPublicacionPortal
} from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(
    private http: HttpClient,
    private sharedDataSvc: SharedDataService
  ) { }

  init(): Promise<void> {
    // Realiza cualquier inicialización necesaria aquí
    // Devuelve una Promise que se resuelve cuando la inicialización está completa
    return new Promise((resolve, reject) => {
      // Aquí va tu código de inicialización
      resolve();
    });
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // CMS
  //-----------------------------------------------------------------------------------------------------------------------------------------------
  getCategoriasNoticias(params = {}): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/categorias-noticias`, { params: params });
  }
  getNoticias(params = {}): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/noticias`, { params: params });
  }
  getNoticia(noticia_id): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/noticias/${noticia_id}`);
  }
  postNoticia(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/noticias`, data, httpOptions);
  }
  putNoticia(noticia_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/noticias/${noticia_id}`, data, httpOptions);
  }
  deleteNoticia(noticia_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/noticias/${noticia_id}`, httpOptions);
  }

  getEnlacesPrensa(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/enlaces-prensa`, { params: params })
      .pipe(
        map(response => {
          response.enlaces_prensa = plainToClass(EnlacePrensa, response.enlaces_prensa);
          return response;
        })
      );
  }
  getEnlacePrensa(enlace_prensa_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/enlaces-prensa/${enlace_prensa_id}`)
      .pipe(
        map(response => {
          response.enlace_prensa = plainToClass(EnlacePrensa, response.enlace_prensa);
          return response;
        })
      );
  }
  postEnlacePrensa(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/enlaces-prensa`, data, httpOptions)
      .pipe(
        map(response => {
          response.enlace_prensa = plainToClass(EnlacePrensa, response.enlace_prensa);
          return response;
        })
      );
  }
  putEnlacePrensa(enlace_prensa_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/enlaces-prensa/${enlace_prensa_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.enlace_prensa = plainToClass(EnlacePrensa, response.enlace_prensa);
          return response;
        })
      );
  }
  deleteEnlacePrensa(enlace_prensa_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/enlaces-prensa/${enlace_prensa_id}`, httpOptions);
  }

  getBanners(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/banners`, { params: params })
      .pipe(
        map(response => {
          response.banners = plainToClass(Banner, response.banners);
          return response;
        })
      )
  }

  getBanner(propiedad_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/banners/${propiedad_id}`)
      .pipe(
          map(response => {
              response.banner = plainToClass(Banner, response.banner);
              return response;
          })
      )
  }

  postBanner(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/banners`, data, httpOptions);
  }
  putBanner(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/banners/${propiedad_id}`, data, httpOptions);
  }
  deleteBanner(banner_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/banners/${banner_id}`, httpOptions);
  }

  getSlideshows(params = {}): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/slideshows`, { params: params });
  }
  getSlideshow(propiedad_id): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/slideshows/${propiedad_id}`);
  }
  putSlideshow(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/slideshows/${propiedad_id}`, data, httpOptions);
  }

  getCmsPropiedades(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/cms-propiedades`, { params: params })
      .pipe(
        map(response => {
          response.propiedades = plainToClass(CmsPropiedad, response.propiedades);
          return response;
        })
      );
  }
  getCmsPropiedad(propiedad_id, params): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/cms-propiedades/${propiedad_id}`, { params: params })
      .pipe(
        map(response => {
          response.cmsPropiedad = plainToClass(CmsPropiedad, response.cmsPropiedad);
          return response;
        })
      );
  }
  putCmsPropiedad(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/cms-propiedades/${propiedad_id}`, data, httpOptions);
  }
  postCmsPropiedadAssignImage(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/cms-propiedades/${propiedad_id}/assign-image`, data, httpOptions)
      .pipe(
        map(response => {
          response.imagen = plainToClass(Imagen, response.imagen);
          return response;
        })
      );
  }
  putCmsPropiedadPersisImageOrder(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/cms-propiedades/${propiedad_id}/persist-image-order`, data, httpOptions);
  }
  postCmsRelacionPropiedad(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/cms-propiedades/${propiedad_id}/cms-relaciones-propiedades`, data, httpOptions);
  }
  deleteCmsRelacionPropiedad(propiedad_id, propiedad_relacionada_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/cms-propiedades/${propiedad_id}/cms-relaciones-propiedades/${propiedad_relacionada_id}`, httpOptions);
  }

  getGrupos(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/grupos`, { params: params })
      .pipe(
        map(response => {
          response.grupos = plainToClass(Grupo, response.grupos);
          return response;
        })
      );
  }
  getGrupo(grupo_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/grupos/${grupo_id}`)
      .pipe(
        map(response => {
          response.grupo = plainToClass(Grupo, response.grupo);
          return response;
        })
      );
  }

  postGrupo(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/grupos`, data, httpOptions);
  }
  putGrupo(grupo_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/grupos/${grupo_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.grupo = plainToClass(Grupo, response.grupo);
          return response;
        })
      );
  }
  deleteGrupo(grupo_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/grupos/${grupo_id}`, httpOptions);
  }

  postCmsRelacionGrupoPropiedad(grupo_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/grupos/${grupo_id}/cms-relaciones-grupos-propiedades`, data, httpOptions);
  }

  deleteCmsRelacionGrupoPropiedad(grupo_id, propiedad_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/grupos/${grupo_id}/cms-relaciones-grupos-propiedades/${propiedad_id}`, httpOptions);
  }


  getCategoriasPropiedades(params = {}): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/categorias-propiedades`, { params: params });
  }
  getEtiquetas(params = {}): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/etiquetas`, { params: params });
  }

  postImagenPublica(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/imagenes-publicas`, data, httpOptions);
  }
  putImagenPublica(imagen_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/imagenes-publicas/${imagen_id}`, data, httpOptions);
  }
  deleteImagenPublica(imagen_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/imagenes-publicas/${imagen_id}`, httpOptions);
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // CONTACTOS
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getContactos(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/contactos`, { params: params })
      .pipe(
        map(response => {
          response.contactos = plainToClass(Contacto, response.contactos);
          return response;
        })
      );
  }
  getContacto(contacto_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}`)
      .pipe(
        map(response => {
          response.contacto = plainToClass(Contacto, response.contacto);
          return response;
        })
      );
  }
  postContacto(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/contactos`, data, httpOptions)
      .pipe(
        map(response => {
          response.contacto = plainToClass(Contacto, response.contacto);
          this.sharedDataSvc.newElement(response.contacto);
          return response;
        })
      );
  }
  putContacto(contacto_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.contacto = plainToClass(Contacto, response.contacto);
          this.sharedDataSvc.modifiedElement(response.contacto);
          return response;
        })
      );
  }
  deleteContacto(contacto_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}`, httpOptions);
  }

  postTelefonoContacto(contacto_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}/telefonos-contacto`, data, httpOptions)
      .pipe(
        map(response => {
          response.telefono_contacto = plainToClass(TelefonoContacto, response.telefono_contacto);
          return response;
        })
      );
  }
  deleteTelefonoContacto(contacto_id, telefono_contacto_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}/telefonos-contacto/${telefono_contacto_id}`, httpOptions);
  }

  postEmailContacto(contacto_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}/emails-contacto`, data, httpOptions);
  }
  deleteEmailContacto(contacto_id, email_contacto_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/contactos/${contacto_id}/emails-contacto/${email_contacto_id}`, httpOptions);
  }

  postRelacion(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/relaciones`, data, httpOptions)
      .pipe(
        map(response => {
          if (response.hasOwnProperty('relacion')) {
            response.relacion = plainToClass(Relacion, response.relacion);
            this.sharedDataSvc.newElement(response.relacion);
          }
          else {
            response.relaciones = plainToClass(Relacion, response.relaciones);
            this.sharedDataSvc.newElement(response.relaciones);
          }
          return response;
        })
      );
  }
  putRelacion(relacion_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/relaciones/${relacion_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.relacion = plainToClass(Relacion, response.relacion);
          this.sharedDataSvc.modifiedElement(response.relacion);
          return response;
        })
      );
  }
  deleteRelacion(relacion_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/relaciones/${relacion_id}`, httpOptions);
  }

  getTiposRelaciones(params = {}): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/tipos-relaciones`, { params: params });
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // DASHBOARD
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getPropiedadesContratoVence(dias): Observable<any> {

    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/propiedades/contrato-vence/${dias}`)
      .pipe(
        map(response => {
          response.propiedades = plainToClass(Propiedad, response.propiedades);
          return response;
        })
      );
  }

  getPropiedadesArrasVence(dias): Observable<any> {

    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/propiedades/arras-vence/${dias}`)
      .pipe(
        map(response => {
          response.propiedades = plainToClass(Propiedad, response.propiedades);
          return response;
        })
      );
  }

  getActividadesPendientes(usuario_id): Observable<any> {

    if (usuario_id) {

      return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/actividades/tareas-pendientes-por-usuario/${usuario_id}`)
      .pipe(
        map(response => {
          response.actividades = plainToClass(Actividad, response.actividades);
          return response;
        })
      );

    } else {

      return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/actividades/tareas-pendientes`)
      .pipe(
        map(response => {
          response.actividades = plainToClass(Actividad, response.actividades);
          return response;
        })
      );
    }
  }


  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // INVENTARIO / CAPTACION
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getPropiedades(params = {}): Observable<any> {
    //return this.http.get(`${environment.API_SERVER_ADDRESS}/propiedades`, { params: params });
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/propiedades`, { params: params })
      .pipe(
        map(response => {
          response.propiedades = plainToClass(Propiedad, response.propiedades);
          return response;
        })
      );
  }
  getPropiedad(propiedad_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/propiedades/view/${propiedad_id}`)
      .pipe(
        map(response => {
          response.propiedad = plainToClass(Propiedad, response.propiedad);
          return response;
        })
      );
  }
  postPropiedad(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/propiedades`, data, httpOptions)
      .pipe(
        map(response => {
          response.propiedad = plainToClass(Propiedad, response.propiedad);
          this.sharedDataSvc.newElement(response.propiedad);
          return response;
        })
      );

  }
  putPropiedad(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/propiedades/${propiedad_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.propiedad = plainToClass(Propiedad, response.propiedad);
          this.sharedDataSvc.modifiedElement(response.propiedad);
          return response;
        })
      );
  }

  postPrecio(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/precios`, data, httpOptions)
      .pipe(
        map(response => {
          response.precio = plainToClass(Precio, response.precio);
          return response;
        })
      );
  }

  postCaptacionDestacada(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/captaciones-destacadas`, data, httpOptions);
  }
  deleteCaptacionDestacada(captacion_destacada_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/captaciones-destacadas/${captacion_destacada_id}`, httpOptions);
  }


  getImagen(imagen_id): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/imagenes/${imagen_id}`,{ responseType: 'blob' });
  }
  postImagen(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/imagenes`, data, httpOptions);
  }
  deleteImagen(imagen_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/imagenes/${imagen_id}`, httpOptions);
  }

  getArchivo(archivo_id): Observable<any> {
    return this.http.get(`${environment.API_SERVER_ADDRESS}/archivos/${archivo_id}`,{ responseType: 'blob' });
  }
  postArchivo(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/archivos`, data, httpOptions);
  }
  putArchivo(archivo_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put(`${environment.API_SERVER_ADDRESS}/archivos/${archivo_id}`, data, httpOptions);
  }
  deleteArchivo(archivo_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/archivos/${archivo_id}`, httpOptions);
  }

  postParcela(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/propiedades/${propiedad_id}/parcelas`, data, httpOptions)
      .pipe(
        map(response => {
          response.parcela = plainToClass(Parcela, response.parcela);
          return response;
        })
      );
  }
  putParcela(propiedad_id, parcela_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/propiedades/${propiedad_id}/parcelas/${parcela_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.parcela = plainToClass(Parcela, response.parcela);
          return response;
        })
      );
  }
  deleteParcela(propiedad_id, parcela_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/propiedades/${propiedad_id}/parcelas/${parcela_id}`, httpOptions);
  }

  postReferenciaCatastral(propiedad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/propiedades/${propiedad_id}/referencias-catastrales`, data, httpOptions)
      .pipe(
        map(response => {
          response.referencia_catastral = plainToClass(ReferenciaCatastral, response.referencia_catastral);
          return response;
        })
      );
  }
  deleteReferenciaCatastral(propiedad_id, referencia_catastral_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/propiedades/${propiedad_id}/referencias-catastrales/${referencia_catastral_id}`, httpOptions);
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // MENSAJES Y ACTIVIDADES
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getMensajes(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/mensajes`, { params: params })
      .pipe(
        map(response => {
          response.mensajes = plainToClass(Mensaje, response.mensajes);
          return response;
        })
      );
  }
  getMensaje(mensaje_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/mensajes/${mensaje_id}`)
      .pipe(
        map(response => {
          response.mensaje = plainToClass(Mensaje, response.mensaje);
          return response;
        })
      );
  }
  postMensaje(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/mensajes`, data, httpOptions)
      .pipe(
        map(response => {
          response.mensaje = plainToClass(Mensaje, response.mensaje);
          return response;
        })
      );
  }
  deleteMensaje(mensaje_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/mensajes/${mensaje_id}`, httpOptions);
  }

  getActividades(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/actividades`, { params: params })
      .pipe(
        map(response => {
          response.actividades = plainToClass(Actividad, response.actividades);
          return response;
        })
      );
  }
  getActividad(actividad_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/actividades/${actividad_id}`)
      .pipe(
        map(response => {
          response.actividad = plainToClass(Actividad, response.actividad);
          return response;
        })
      );
  }
  postActividad(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/actividades`, data, httpOptions)
      .pipe(
        map(response => {
          response.actividad = plainToClass(Actividad, response.actividad);
          return response;
        })
      );
  }
  putActividad(actividad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/actividades/${actividad_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.actividad = plainToClass(Actividad, response.actividad);
          return response;
        })
      );
  }
  deleteActividad(actividad_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/actividades/${actividad_id}`, httpOptions);
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // LOGS
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getLogs(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/logs`, { params: params })
      .pipe(
        map(response => {
          response.logs = plainToClass(Log, response.logs);
          return response;
        })
      );
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // VENTA
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getOportunidades(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/oportunidades`, { params: params })
      .pipe(
        map(response => {
          response.oportunidades = plainToClass(Oportunidad, response.oportunidades);
          return response;
        })
      );
  }
  getOportunidad(oportunidad_id): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/oportunidades/${oportunidad_id}`)
      .pipe(
        map(response => {
          response.oportunidad = plainToClass(Oportunidad, response.oportunidad);
          return response;
        })
      );
  }
  postOportunidad(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/oportunidades`, data, httpOptions)
      .pipe(
        map(response => {
          response.oportunidad = plainToClass(Oportunidad, response.oportunidad);
          return response;
        })
      );
  }
  putOportunidad(oportunidad_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/oportunidades/${oportunidad_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.oportunidad = plainToClass(Oportunidad, response.oportunidad);
          return response;
        })
      );
  }

  postOportunidadDestacada(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.post(`${environment.API_SERVER_ADDRESS}/oportunidades-destacadas`, data, httpOptions);
  }
  deleteOportunidadDestacada(oportunidad_destacada_id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    return this.http.delete(`${environment.API_SERVER_ADDRESS}/oportunidades-destacadas/${oportunidad_destacada_id}`, httpOptions);
  }

  getIntereses(params = {}): Observable<any> {
    const paramsToSent = { ...params, q_tipo_relacion_id: 3 };
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/relaciones`, { params: paramsToSent })
      .pipe(
        map(response => {
          response.relaciones = plainToClass(Relacion, response.relaciones);
          return response;
        })
      );
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // USUARIO Y CONFIGURACIONES
  //-----------------------------------------------------------------------------------------------------------------------------------------------

  getUsuarioActivo(): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/usuarios/get-activo`)
      .pipe(
        map(response => {
          response.usuario = plainToClass(Usuario, response.usuario);
          return response;
        })
      );
  }
  putUsuario(usuario_id, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      })
    };
    return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/usuarios/edit/${usuario_id}`, data, httpOptions)
      .pipe(
        map(response => {
          response.usuario = plainToClass(Usuario, response.usuario);
          return response;
        })
      );
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // DASHBOARD DE MARKETING
  //-----------------------------------------------------------------------------------------------------------------------------------------------


  getBannersURLRota(): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/banners/url-rota`)
      .pipe(
        map(response => {
          response.banners = plainToClass(Banner, response.banners);
          return response;
        })
      )
  }


  //-----------------------------------------------------------------------------------------------------------------------------------------------
  // CONEXIÓN CON PORTALES
  //-----------------------------------------------------------------------------------------------------------------------------------------------
  
  getPortales(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/portales`, { params: params })
      .pipe(
        map(response => {
            response.portales = plainToClass(Portal, response.portales);               
            return response;
        })
      )
  }

  getPortalesConCmspropiedad(cms_propiedad_id, params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/portales/get-con-cmspropiedad/${cms_propiedad_id}`, { params: params })
      .pipe(
        map(response => {
            response.portales = plainToClass(Portal, response.portales);               
            return response;
        })
      )
  }

  postEstadoPublicacionPortal(data): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          //        'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        })
      };
      return this.http.post<any>(`${environment.API_SERVER_ADDRESS}/estados-publicaciones-portales/add`, data, httpOptions).
        pipe(
            map(response => {
                response.estadoPublicacionPortal = plainToClass(EstadoPublicacionPortal, response.estadoPublicacionPortal);
                return response;
            })
      );
    }

    putEstadoPublicacionPortal(estado_publicacion_id, data): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                //        'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest',
            })
        };
        return this.http.put<any>(`${environment.API_SERVER_ADDRESS}/estados-publicaciones-portales/edit/${estado_publicacion_id}`, data, httpOptions).
            pipe(
                map(response => {
                    response.estadoPublicacionPortal = plainToClass(EstadoPublicacionPortal, response.estadoPublicacionPortal);
                    return response;
                })
            );
    }


    deletePublicacionPortal(estado_publicacion_id): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Requested-With': 'XMLHttpRequest'
        })
      };
      return this.http.delete(`${environment.API_SERVER_ADDRESS}/estados-publicaciones-portales/delete/${estado_publicacion_id}`, httpOptions);
    }    

  // OTROS -----------------------------------------------------------------------------------------------------------------------------------------------

  getUsuarios(params = {}): Observable<any> {
    return this.http.get<any>(`${environment.API_SERVER_ADDRESS}/usuarios`, { params: params })
      .pipe(
        map(response => {
          response.usuarios = plainToClass(Usuario, response.usuarios);
          return response;
        })
      );
  }
}