<a *ngIf="hook=='header'" (click)="openModal('interesAdd')" type="button" class="nav-link nav-link-style btn">
  <span [data-feather]="'crosshair'" class=" ficon font-medium-5 feather"></span>
</a>
<a *ngIf="hook=='propiedad'" class="btn btn-primary btn-sm" (click)="openModal('interesAdd')">
  <span [data-feather]="'plus'"></span>
</a>
<a *ngIf="hook=='contacto'" class="btn btn-primary btn-sm" (click)="openModal('interesAdd')">
  <span [data-feather]="'plus'"></span>
</a>
<a *ngIf="hook=='oportunidad'" class="btn btn-primary btn-icon btn-sm" (click)="openModal('interesAdd')">
  <div class="d-flex align-items-center">
    <span [data-feather]="'plus'"></span> <span>interés</span>
  </div>
</a>

<!-- Modal -->
<ng-template #interesAddModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="interesAddForm" novalidate  class=""  (ngSubmit)="onSubmitInteres()">
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="form-group mb-1" *ngIf="hook == 'header' || hook == 'contacto' || hook == 'oportunidad'">
        <label for="noticia-_ids">Propiedades</label>
        <ng-select
          [items]="propiedades"
          [multiple]="true"
          [searchable]="true"
          [selectableGroup]="true"
          [closeOnSelect]="true"
          bindLabel="id"
          bindValue="id"
          formControlName="propiedades_ids"
        >
        </ng-select>
        <span *ngIf="submitted && interesAddForm.controls.propiedades_ids.errors" class="invalid-form">
          <small class="form-text text-danger" *ngIf="interesAddForm.controls.propiedades_ids.errors.required">Es necesario seleccionar alguna propiedad</small>
        </span>
      </div>

      <!--
      <div class="form-group mb-2">
        <label for="relacion-tipo_relacion">Apunte</label>
        <textarea rows="2" class="form-control" formControlName="notas" id="notas" name="relacion-notas">
        </textarea>
      </div>
      -->

      <div class="form-group mb-2">
        <label for="relacion-origen">Origen del interés</label>
        <input rows="2" class="form-control" formControlName="origen" id="notas" name="relacion-origen" list="tipo_origen">
        <datalist id="tipo_origen">
          <option value="Llamada teléfonica">
          <option value="Correo electrónico">
          <option>Web</option>
          <option>Redes sociales</option>
          <option>Kyero</option>
          <option>Idealista</option>
          <option>Right Move</option>
          <option>LuxuryState</option>
          <option>SpainHouses</option>
          <option>thinkSPAIN</option>
          <option>Fotocasa</option>
          <option>Recomendación comercial</option>
        </datalist>
        <span *ngIf="submitted && interesAddForm.controls.origen.errors" class="invalid-form">
          <small class="form-text text-danger" *ngIf="interesAddForm.controls.origen.errors.required">Debe indicar el origen</small>
        </span>
      </div>

      <div *ngIf="hook == 'propiedad' || hook == 'header'">
        <ul  ngbNav #contactoNav="ngbNav" class="nav-tabs nav-justified" (navChange)="contactoNavChange($event)">

          <li [ngbNavItem]="1">
            <a ngbNavLink >Contacto existente</a>
            <ng-template ngbNavContent>
              <div class="form-group mb-2">
                <label for="noticia-category">Seleccionar contacto</label>

                <ng-select
                  [items]="contactos"
                  [searchable]="true"
                  bindValue="id"
                  placeholder="- Elija contacto -"
                  formControlName="contacto_id"
                  [searchFn]="customSearchContacto"
                  required="required"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{item.nombre}}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    {{item.nombre}}
                  </ng-template>
                </ng-select>
                <span *ngIf="submitted && interesAddForm.controls.contacto_id.errors" class="invalid-form">
                  <small class="form-text text-danger" *ngIf="interesAddForm.controls.contacto_id.errors.required">Este campo es requerido</small>
                </span>
              </div>

            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Nuevo</a>
            <ng-template ngbNavContent formGroupName="contacto">

              <label>Nombre </label>
              <div class="form-group" >
                <input type="text" placeholder="Nombre" class="form-control" formControlName="nombre" />
                <span *ngIf="submitted && interesAddForm.controls.contacto.controls.nombre.errors" class="invalid-form">
                  <small class="form-text text-danger" *ngIf="interesAddForm.controls.contacto.controls.nombre.errors.required">Este campo es requerido</small>
                </span>
              </div>

              <div formArrayName="telefonos_contacto">
                <div class="row" formGroupName="0">
                  <div class="col-8">
                    <div class="form-group">
                      <label>Telefono </label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="telefono"
                        ng2TelInput
                        [ng2TelInputOptions]="{initialCountry: 'es', preferredCountries: preferredCountries, separateDialCode:true, autoHideDialCode: false, formatOnDisplay: true}"
                        (countryChange)="onChangePrefix($event)"
                        />
                      <span *ngIf="submitted && interesAddForm.controls.contacto.controls.telefonos_contacto.controls[0].controls.telefono.errors" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="interesAddForm.controls.contacto.controls.telefonos_contacto.controls[0].controls.telefono.errors.required">Este campo es requerido</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Etiqueta </label>
                      <input type="text" placeholder="Etiqueta" class="form-control" formControlName="etiqueta" list="tipo_etiqueta_telefono" />
                      <datalist id="tipo_etiqueta_telefono">
                        <option value="Personal">
                        <option value="Trabajo">
                        <option value="Móvil">
                      </datalist>
                    </div>
                  </div>
                </div>
              </div>
              <div formArrayName="emails_contacto">
                <div class="row" formGroupName="0">
                  <div class="col-8">
                    <div class="form-group">
                      <label>Correo electrónico </label>
                      <input type="text" placeholder="Email" class="form-control" formControlName="email" />
                      <span *ngIf="submitted && interesAddForm.controls.contacto.controls.emails_contacto.controls[0].controls.email.errors" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="interesAddForm.controls.contacto.controls.emails_contacto.controls[0].controls.email.errors.email">Se requiere un email válido</small>
                        <small class="form-text text-danger" *ngIf="interesAddForm.controls.contacto.controls.emails_contacto.controls[0].controls.email.errors.required">Este campo es requerido</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Etiqueta </label>
                      <input type="text" placeholder="Etiqueta" class="form-control" formControlName="etiqueta" list="tipo_etiqueta_email" />
                      <datalist id="tipo_etiqueta_email">
                        <option value="Personal">
                        <option value="Trabajo">
                      </datalist>
                    </div>
                  </div>
                </div>
              </div>
              <span *ngIf="submitted && interesAddForm.controls.contacto.errors" class="invalid-form">
                <small class="form-text text-danger" *ngIf="interesAddForm.controls.contacto.errors.alMenosUnContacto">Se requiere añadir al menos una forma de contacto</small>
              </span>

              </ng-template>
          </li>

        </ul>
        <div [ngbNavOutlet]="contactoNav" class="mt-0"></div>
      </div>

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modal.close('Cancel click')" rippleEffect>
        Cancelar
      </button>
      <button *ngIf="hook == 'header' || hook == 'propiedad'" type="submit" class="btn btn-primary" rippleEffect (click)="redirect(true)">
        Guardar e ir al cliente
      </button>
      <button type="submit" class="btn btn-primary" rippleEffect (click)="redirect(false)">
        Guardar
      </button>
    </div>
  </form>
</ng-template>
<!-- / Modal -->